var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-modal",
    {
      attrs: {
        title: _vm.title,
        width: 800,
        visible: _vm.visible,
        confirmLoading: _vm.confirmLoading,
        cancelText: "关闭"
      },
      on: { ok: _vm.handleOk, cancel: _vm.handleCancel }
    },
    [
      _c(
        "a-spin",
        { attrs: { spinning: _vm.confirmLoading } },
        [
          _c(
            "a-form",
            { attrs: { form: _vm.form } },
            [
              _c(
                "a-row",
                { attrs: { gutter: { xs: 8, sm: 16, md: 24, lg: 32 } } },
                [
                  _c(
                    "a-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "a-form-item",
                        {
                          staticStyle: { "margin-right": "-35px" },
                          attrs: {
                            labelCol: _vm.labelCol,
                            wrapperCol: _vm.wrapperCol,
                            label: "模板CODE"
                          }
                        },
                        [
                          _c("a-input", {
                            directives: [
                              {
                                name: "decorator",
                                rawName: "v-decorator",
                                value: [
                                  "templateCode",
                                  _vm.validatorRules.templateCode
                                ],
                                expression:
                                  "['templateCode', validatorRules.templateCode ]"
                              }
                            ],
                            attrs: {
                              disabled: _vm.disable,
                              placeholder: "请输入模板编码"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "a-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "a-form-item",
                        {
                          attrs: {
                            labelCol: _vm.labelCol,
                            wrapperCol: _vm.wrapperCol,
                            label: "模板类型"
                          }
                        },
                        [
                          _c("j-dict-select-tag", {
                            directives: [
                              {
                                name: "decorator",
                                rawName: "v-decorator",
                                value: [
                                  "templateType",
                                  _vm.validatorRules.templateType
                                ],
                                expression:
                                  "['templateType', validatorRules.templateType ]"
                              }
                            ],
                            attrs: {
                              triggerChange: true,
                              dictCode: "msgType",
                              placeholder: "请选择模板类型"
                            },
                            on: { change: _vm.handleChangeTemplateType }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "a-row",
                { staticClass: "form-row", attrs: { gutter: 24 } },
                [
                  _c(
                    "a-col",
                    { attrs: { span: 24, pull: "2" } },
                    [
                      _c(
                        "a-form-item",
                        {
                          staticStyle: { "margin-left": "-15px" },
                          attrs: {
                            labelCol: _vm.labelCol,
                            wrapperCol: _vm.wrapperCol,
                            label: "模板标题"
                          }
                        },
                        [
                          _c("a-input", {
                            directives: [
                              {
                                name: "decorator",
                                rawName: "v-decorator",
                                value: [
                                  "templateName",
                                  _vm.validatorRules.templateName
                                ],
                                expression:
                                  "['templateName', validatorRules.templateName]"
                              }
                            ],
                            staticStyle: { width: "122%" },
                            attrs: { placeholder: "请输入模板标题" }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "a-row",
                { staticClass: "form-row", attrs: { gutter: 24 } },
                [
                  _c(
                    "a-col",
                    { attrs: { span: 24, pull: "4" } },
                    [
                      _c(
                        "a-form-item",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: !_vm.useEditor,
                              expression: "!useEditor"
                            }
                          ],
                          staticStyle: { "margin-left": "4px", width: "126%" },
                          attrs: {
                            labelCol: _vm.labelCol,
                            wrapperCol: _vm.wrapperCol,
                            label: "模板内容"
                          }
                        },
                        [
                          _c("a-textarea", {
                            directives: [
                              {
                                name: "decorator",
                                rawName: "v-decorator",
                                value: [
                                  "templateContent",
                                  _vm.validatorRules.templateContent
                                ],
                                expression:
                                  "['templateContent', validatorRules.templateContent ]"
                              }
                            ],
                            attrs: {
                              placeholder: "请输入模板内容",
                              autosize: { minRows: 8, maxRows: 8 }
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "a-row",
                { staticClass: "form-row", attrs: { gutter: 24 } },
                [
                  _c(
                    "a-col",
                    { attrs: { span: 24, pull: "4" } },
                    [
                      _c(
                        "a-form-item",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.useEditor,
                              expression: "useEditor"
                            }
                          ],
                          staticStyle: { "margin-left": "4px", width: "126%" },
                          attrs: {
                            labelCol: _vm.labelCol,
                            wrapperCol: _vm.wrapperCol,
                            label: "模板内容"
                          }
                        },
                        [
                          _c("j-editor", {
                            model: {
                              value: _vm.templateEditorContent,
                              callback: function($$v) {
                                _vm.templateEditorContent = $$v
                              },
                              expression: "templateEditorContent"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }