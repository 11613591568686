var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-drawer",
    {
      attrs: {
        title: _vm.title,
        width: _vm.drawerWidth,
        visible: _vm.visible,
        confirmLoading: _vm.confirmLoading
      },
      on: { close: _vm.handleCancel }
    },
    [
      _c(
        "div",
        {
          style: {
            width: "100%",
            border: "1px solid #e9e9e9",
            padding: "10px 16px",
            background: "#fff"
          }
        },
        [
          _c(
            "a-spin",
            { attrs: { spinning: _vm.confirmLoading } },
            [
              _c(
                "a-form-model",
                {
                  ref: "form",
                  attrs: { model: _vm.model, rules: _vm.validatorRules }
                },
                [
                  _c(
                    "a-form-model-item",
                    {
                      attrs: {
                        label: "菜单类型",
                        labelCol: _vm.labelCol,
                        wrapperCol: _vm.wrapperCol
                      }
                    },
                    [
                      _c(
                        "a-radio-group",
                        {
                          on: { change: _vm.onChangeMenuType },
                          model: {
                            value: _vm.model.menuType,
                            callback: function($$v) {
                              _vm.$set(_vm.model, "menuType", $$v)
                            },
                            expression: "model.menuType"
                          }
                        },
                        [
                          _c("a-radio", { attrs: { value: 0 } }, [
                            _vm._v("一级菜单")
                          ]),
                          _c("a-radio", { attrs: { value: 1 } }, [
                            _vm._v("子菜单")
                          ]),
                          _c("a-radio", { attrs: { value: 2 } }, [
                            _vm._v("按钮/权限")
                          ])
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "a-form-model-item",
                    {
                      attrs: {
                        labelCol: _vm.labelCol,
                        wrapperCol: _vm.wrapperCol,
                        label: _vm.menuLabel,
                        prop: "name",
                        hasFeedback: ""
                      }
                    },
                    [
                      _c("a-input", {
                        attrs: {
                          placeholder: "请输入菜单名称",
                          readOnly: _vm.disableSubmit
                        },
                        model: {
                          value: _vm.model.name,
                          callback: function($$v) {
                            _vm.$set(_vm.model, "name", $$v)
                          },
                          expression: "model.name"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "a-form-model-item",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.model.menuType != 0,
                          expression: "model.menuType!=0"
                        }
                      ],
                      attrs: {
                        label: "上级菜单",
                        labelCol: _vm.labelCol,
                        wrapperCol: _vm.wrapperCol,
                        "validate-status": _vm.validateStatus,
                        hasFeedback: true,
                        required: true
                      }
                    },
                    [
                      _c("span", { attrs: { slot: "help" }, slot: "help" }, [
                        _vm._v(
                          _vm._s(
                            _vm.validateStatus == "error"
                              ? "请选择上级菜单"
                              : "  "
                          )
                        )
                      ]),
                      _c("a-tree-select", {
                        staticStyle: { width: "100%" },
                        attrs: {
                          dropdownStyle: {
                            maxHeight: "200px",
                            overflow: "auto"
                          },
                          treeData: _vm.treeData,
                          placeholder: "请选择父级菜单",
                          disabled: _vm.disableSubmit
                        },
                        on: { change: _vm.handleParentIdChange },
                        model: {
                          value: _vm.model.parentId,
                          callback: function($$v) {
                            _vm.$set(_vm.model, "parentId", $$v)
                          },
                          expression: "model.parentId"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "a-form-model-item",
                    {
                      attrs: {
                        labelCol: _vm.labelCol,
                        wrapperCol: _vm.wrapperCol,
                        prop: "url",
                        label: "菜单路径"
                      }
                    },
                    [
                      _c("a-input", {
                        attrs: {
                          placeholder: "请输入菜单路径",
                          readOnly: _vm.disableSubmit
                        },
                        model: {
                          value: _vm.model.url,
                          callback: function($$v) {
                            _vm.$set(_vm.model, "url", $$v)
                          },
                          expression: "model.url"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "a-form-model-item",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.show,
                          expression: "show"
                        }
                      ],
                      attrs: {
                        labelCol: _vm.labelCol,
                        wrapperCol: _vm.wrapperCol,
                        prop: "component",
                        label: "前端组件"
                      }
                    },
                    [
                      _c("a-input", {
                        attrs: {
                          placeholder: "请输入前端组件",
                          readOnly: _vm.disableSubmit
                        },
                        model: {
                          value: _vm.model.component,
                          callback: function($$v) {
                            _vm.$set(_vm.model, "component", $$v)
                          },
                          expression: "model.component"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "a-form-model-item",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.model.menuType == 0,
                          expression: "model.menuType==0"
                        }
                      ],
                      attrs: {
                        labelCol: _vm.labelCol,
                        wrapperCol: _vm.wrapperCol,
                        label: "默认跳转地址"
                      }
                    },
                    [
                      _c("a-input", {
                        attrs: {
                          placeholder: "请输入路由参数 redirect",
                          readOnly: _vm.disableSubmit
                        },
                        model: {
                          value: _vm.model.redirect,
                          callback: function($$v) {
                            _vm.$set(_vm.model, "redirect", $$v)
                          },
                          expression: "model.redirect"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "a-form-model-item",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: !_vm.show,
                          expression: "!show"
                        }
                      ],
                      attrs: {
                        labelCol: _vm.labelCol,
                        wrapperCol: _vm.wrapperCol,
                        prop: "perms",
                        label: "授权标识"
                      }
                    },
                    [
                      _c("a-input", {
                        attrs: {
                          placeholder: "请输入授权标识, 如: user:list",
                          readOnly: _vm.disableSubmit
                        },
                        model: {
                          value: _vm.model.perms,
                          callback: function($$v) {
                            _vm.$set(_vm.model, "perms", $$v)
                          },
                          expression: "model.perms"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "a-form-model-item",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: !_vm.show,
                          expression: "!show"
                        }
                      ],
                      attrs: {
                        labelCol: _vm.labelCol,
                        wrapperCol: _vm.wrapperCol,
                        label: "授权策略"
                      }
                    },
                    [
                      _c("j-dict-select-tag", {
                        attrs: {
                          placeholder: "请选择授权策略",
                          type: "radio",
                          dictCode: "global_perms_type"
                        },
                        model: {
                          value: _vm.model.permsType,
                          callback: function($$v) {
                            _vm.$set(_vm.model, "permsType", $$v)
                          },
                          expression: "model.permsType"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "a-form-model-item",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: !_vm.show,
                          expression: "!show"
                        }
                      ],
                      attrs: {
                        labelCol: _vm.labelCol,
                        wrapperCol: _vm.wrapperCol,
                        label: "状态"
                      }
                    },
                    [
                      _c("j-dict-select-tag", {
                        attrs: {
                          placeholder: "请选择状态",
                          type: "radio",
                          dictCode: "valid_status"
                        },
                        model: {
                          value: _vm.model.status,
                          callback: function($$v) {
                            _vm.$set(_vm.model, "status", $$v)
                          },
                          expression: "model.status"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "a-form-model-item",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.show,
                          expression: "show"
                        }
                      ],
                      attrs: {
                        labelCol: _vm.labelCol,
                        wrapperCol: _vm.wrapperCol,
                        label: "菜单图标"
                      }
                    },
                    [
                      _c(
                        "a-input",
                        {
                          attrs: {
                            placeholder: "点击选择图标",
                            readOnly: _vm.disableSubmit
                          },
                          model: {
                            value: _vm.model.icon,
                            callback: function($$v) {
                              _vm.$set(_vm.model, "icon", $$v)
                            },
                            expression: "model.icon"
                          }
                        },
                        [
                          _c("a-icon", {
                            attrs: { slot: "addonAfter", type: "setting" },
                            on: { click: _vm.selectIcons },
                            slot: "addonAfter"
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "a-form-model-item",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.show,
                          expression: "show"
                        }
                      ],
                      attrs: {
                        labelCol: _vm.labelCol,
                        wrapperCol: _vm.wrapperCol,
                        prop: "sortNo",
                        label: "排序"
                      }
                    },
                    [
                      _c("a-input-number", {
                        staticStyle: { width: "200px" },
                        attrs: {
                          placeholder: "请输入菜单排序",
                          readOnly: _vm.disableSubmit
                        },
                        model: {
                          value: _vm.model.sortNo,
                          callback: function($$v) {
                            _vm.$set(_vm.model, "sortNo", $$v)
                          },
                          expression: "model.sortNo"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "a-form-model-item",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.show,
                          expression: "show"
                        }
                      ],
                      attrs: {
                        labelCol: _vm.labelCol,
                        wrapperCol: _vm.wrapperCol,
                        label: "是否路由菜单"
                      }
                    },
                    [
                      _c("a-switch", {
                        attrs: {
                          checkedChildren: "是",
                          unCheckedChildren: "否"
                        },
                        model: {
                          value: _vm.model.route,
                          callback: function($$v) {
                            _vm.$set(_vm.model, "route", $$v)
                          },
                          expression: "model.route"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "a-form-model-item",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.show,
                          expression: "show"
                        }
                      ],
                      attrs: {
                        labelCol: _vm.labelCol,
                        wrapperCol: _vm.wrapperCol,
                        label: "隐藏路由"
                      }
                    },
                    [
                      _c("a-switch", {
                        attrs: {
                          checkedChildren: "是",
                          unCheckedChildren: "否"
                        },
                        model: {
                          value: _vm.model.hidden,
                          callback: function($$v) {
                            _vm.$set(_vm.model, "hidden", $$v)
                          },
                          expression: "model.hidden"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "a-form-model-item",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.show,
                          expression: "show"
                        }
                      ],
                      attrs: {
                        labelCol: _vm.labelCol,
                        wrapperCol: _vm.wrapperCol,
                        label: "是否缓存路由"
                      }
                    },
                    [
                      _c("a-switch", {
                        attrs: {
                          checkedChildren: "是",
                          unCheckedChildren: "否"
                        },
                        model: {
                          value: _vm.model.keepAlive,
                          callback: function($$v) {
                            _vm.$set(_vm.model, "keepAlive", $$v)
                          },
                          expression: "model.keepAlive"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "a-form-model-item",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.show,
                          expression: "show"
                        }
                      ],
                      attrs: {
                        labelCol: _vm.labelCol,
                        wrapperCol: _vm.wrapperCol,
                        label: "是否基础菜单"
                      }
                    },
                    [
                      _c("a-switch", {
                        attrs: {
                          checkedChildren: "是",
                          unCheckedChildren: "否"
                        },
                        model: {
                          value: _vm.model.baseFlag,
                          callback: function($$v) {
                            _vm.$set(_vm.model, "baseFlag", $$v)
                          },
                          expression: "model.baseFlag"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "a-form-model-item",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.show,
                          expression: "show"
                        }
                      ],
                      attrs: {
                        labelCol: _vm.labelCol,
                        wrapperCol: _vm.wrapperCol,
                        label: "聚合路由"
                      }
                    },
                    [
                      _c("a-switch", {
                        attrs: {
                          checkedChildren: "是",
                          unCheckedChildren: "否"
                        },
                        model: {
                          value: _vm.model.alwaysShow,
                          callback: function($$v) {
                            _vm.$set(_vm.model, "alwaysShow", $$v)
                          },
                          expression: "model.alwaysShow"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "a-form-model-item",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.show,
                          expression: "show"
                        }
                      ],
                      attrs: {
                        labelCol: _vm.labelCol,
                        wrapperCol: _vm.wrapperCol,
                        label: "打开方式"
                      }
                    },
                    [
                      _c("a-switch", {
                        attrs: {
                          checkedChildren: "外部",
                          unCheckedChildren: "内部"
                        },
                        model: {
                          value: _vm.model.internalOrExternal,
                          callback: function($$v) {
                            _vm.$set(_vm.model, "internalOrExternal", $$v)
                          },
                          expression: "model.internalOrExternal"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _c("icons", {
                attrs: { iconChooseVisible: _vm.iconChooseVisible },
                on: {
                  choose: _vm.handleIconChoose,
                  close: _vm.handleIconCancel
                }
              })
            ],
            1
          ),
          _c(
            "a-row",
            { style: { textAlign: "right" } },
            [
              _c(
                "a-button",
                {
                  style: { marginRight: "8px" },
                  on: { click: _vm.handleCancel }
                },
                [_vm._v("\n        关闭\n      ")]
              ),
              _c(
                "a-button",
                {
                  attrs: { disabled: _vm.disableSubmit, type: "primary" },
                  on: { click: _vm.handleOk }
                },
                [_vm._v("确定")]
              )
            ],
            1
          )
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }